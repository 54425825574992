@import url('https://fonts.googleapis.com/css?family=Cantata+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

$border-radius: 0;
$primary: #1272b0;
$font-family-sans-serif: 'Roboto', sans-serif;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.jumbotron {
  padding: 2rem 2rem;
}

h2 {
  padding: 0 0 1rem 0;
}

h3 {
  font-size: 1.5rem;
  font-family: 'Cantata One', sans-serif;
  padding: 0 0 1rem 0;
}
