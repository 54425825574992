lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
.lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
}
.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
}
.lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
}
.lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
}
ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
ol.lst-kix_list_2-2 {
  list-style-type: none;
}
ol.lst-kix_list_2-3 {
  list-style-type: none;
}
ol.lst-kix_list_2-4 {
  list-style-type: none;
}
ol.lst-kix_list_2-5 {
  list-style-type: none;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
ol.lst-kix_list_2-1 {
  list-style-type: none;
}
ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
.lst-kix_list_1-0 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: 'o  ';
}
.lst-kix_list_1-2 > li:before {
  content: '\0025aa  ';
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
ol.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_2-8 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_1-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_1-7 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
.lst-kix_list_1-5 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_1-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_2-0 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, upper-latin) '. ';
}
.lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
}
ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
.lst-kix_list_1-8 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
}
.lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c11 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
}
.c10 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: italic;
}
.c2 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
}
.c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
}
.c14 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-style: italic;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c0 {
  padding-top: 0pt;
  padding-bottom: 0.9pt;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c13 {
  padding-top: 0pt;
  padding-bottom: 1.1pt;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c9 {
  padding-top: 0pt;
  padding-bottom: 1pt;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c5 {
  background-color: #ffffff;
  max-width: 453.6pt;
  padding: 70.8pt 70.8pt 70.8pt 70.8pt;
}
.c12 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c7 {
  color: inherit;
  text-decoration: inherit;
}
.c1 {
  padding: 0;
  margin: 0;
}
.c8 {
  height: 11pt;
}
.c6 {
  text-indent: 35.4pt;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
/* li {
  color: #000000;
}
p {
  margin: 0;
  color: #000000;
}
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  padding-bottom: 2pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
} */
